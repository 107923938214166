import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Area,
  ComposedChart,
  BarChart,
  ResponsiveContainer
} from "recharts";
import moment from "moment";
import "./dashboard.css";
import { NavLink, useLocation } from "react-router-dom";
import { Chart } from "react-google-charts";
import { GridLoader } from "react-spinners";
import axios from "axios";

const DataForDateDash = () => {
  const [responseData, setResponseData] = useState([]);
  const [Ttdata, setTtData] = useState([]);
  const [CashData, setCashData] = useState([]);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [TotalProfit, setTotalProfit] = useState(0);
  const [Expense, setExpense] = useState(0);
  const [Bottle, setBottle] = useState(0);
  const [BottleAmount, setBottleAmount] = useState(0);
  const [Biscuit, setBiscuit] = useState(0);
  const [BiscuitAmount, setBiscuitAmount] = useState(0);
  const [TotalSale, setTotalSale] = useState(0);
  const [cashInHand, setCashInHand] = useState(0);
  const [online, setOnline] = useState(0);
  const [loading, setLoading] = useState(false);
  const [avdata, setAvData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [teaData, setTeaData] = useState([]);
  const [cigeretteData,setCigeretteData] = useState([]);
  const [pouchData,setPouchData] = useState([]);
  const [onlineExpense,setOnlineExpense] = useState(0);

    //stocks updates
    const [stockdata, setStockData] = useState([]);
    const [processedStockData, setProcessedStockData] = useState([]);
    const [currentStocks, setCurrentStocks] = useState({});

  const location = useLocation();
  const datas = location.state;

   //stock data
   const StockData = async () => {
    const id = localStorage.getItem("key");
    try {
      setLoading(true);
      // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
      const response = await axios.get(`${process.env.REACT_APP_API_URL}item-stock/user/${id}`);
      setStockData(response.data);
      console.log("response data for stock", response.data);
            setLoading(false);
    } catch (err) {
      console.error('An error occurred while fetching data');
      setLoading(false);
    }
  };
  useEffect(() => {
    if (stockdata.length > 0) {
      processData();
      // extractCurrentStocks();
    }
  }, [stockdata]);
  const processData = () => {
    const processed = stockdata.reduce((acc, item) => {
      item.stockDeductions.forEach((deduction) => {
        const date = moment(deduction.date).format("MM/DD");
        const existingEntry = acc.find((entry) => entry.date === date);
  
        if (existingEntry) {
          // Accumulate the current stock for the current item
          existingEntry[item.itemName] =
            (existingEntry[item.itemName] || 0) + deduction.currentStock;
        } else {
          // Create a new entry if not found for the date
          const newEntry = { date };
          newEntry[item.itemName] = deduction.currentStock;
          acc.push(newEntry);
        }
      });
      return acc;
    }, []);
  
    // Sorting based on date in MM/DD format
    processed.sort(
      (a, b) =>
        moment(a.date, "MM/DD").valueOf() - moment(b.date, "MM/DD").valueOf()
    );
  
    setProcessedStockData(processed); // Assuming this is a state setter function
  };
  

  const addDateHandler = async (e) => {
    // e.preventDefault();

    const userId = localStorage.getItem("key");


    setLoading(true);

    try {
      const adjustedStartDate = new Date(datas.startDate);
      adjustedStartDate.setDate(adjustedStartDate.getDate());
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}calculation/dateRange`,
        {
          params: {
            startDate: adjustedStartDate,
            endDate: datas.endDate,
            userId: userId,
          },
        }
      );
      const data = response.data;
      const formattedData = response.data.map((item) => ({
        name:  moment(item.date).format("DD/MM"), // or item.date.split('-')[2] for just the day part
        Average: item.average,
      }));
      setAvData(formattedData);

      // for last line chart
      const lineFormatedData = response.data.map((item) => ({
        name:  moment(item.date).format("DD/MM"), // or item.date.split('-')[2] for just the day part
        totalSale: item.totalSale,
        chaiTotal: item.chaiTotal,
        bottleTotal: item.bottleAmount,
        biscuitTotal: item.biscuitAmount,
      }));
      setTtData(lineFormatedData);

      // for area chart expense
      const ExpenseformattedData = response.data.map((item) => ({
        name:  moment(item.date).format("DD/MM"), // or item.date.split('-')[2] for just the day part
        Expense: item.expense,
      }));

      setExpenseData(ExpenseformattedData);

      const TeaFormated = response.data.map((item) => ({
        name:  moment(item.date).format("DD/MM"), // or item.date.split('-')[2] for just the day part
        Tea: item.tea,
      }));

      setTeaData(TeaFormated);

      const CigeretteFormated = response.data.map((item) => ({
        name:  moment(item.date).format("DD/MM"), // or item.date.split('-')[2] for just the day part
        Tea: item.ciggaratteAmount,
      }));

      setCigeretteData(CigeretteFormated);

      const PouchFormated = response.data.map((item) => ({
        name:  moment(item.date).format("DD/MM"), // or item.date.split('-')[2] for just the day part
        Tea: item.pouchAmount,
      }));

      setPouchData(PouchFormated);
      // Calculate totals
      let totalProfit = 0;
      let totalExpense = 0;
      let totalOnlineExpense = 0;
      let totalBottle = 0;
      let totalBottleAmount = 0;
      let totalBiscuit = 0;
      let totalBiscuitAmount = 0;
      let totalOnline = 0;
      let totalCashInHand = 0;
      let totalSale = 0;

      data.forEach((item) => {
        totalProfit += item.profit;
        totalExpense += item.expense;
        totalOnlineExpense += item.onlineExpense;
        totalBottle += item.bottle;
        totalBottleAmount += item.bottleAmount;
        totalBiscuit += item.biscuit;
        totalBiscuitAmount += item.biscuitAmount;
        totalOnline += item.online;
        totalCashInHand += item.cashInHand;
        totalSale += item.totalSale;
        item.date =  moment(item.date).format("DD/MM")
      });

      // Update state with totals
      setResponseData(data);
      setTotalProfit(totalProfit);
      setExpense(totalExpense-totalOnlineExpense);
      setOnlineExpense(totalOnlineExpense)
      setBottle(totalBottle);
      setBottleAmount(totalBottleAmount);
      setBiscuit(totalBiscuit);
      setBiscuitAmount(totalBiscuitAmount);
      setOnline(totalOnline);
      setCashInHand(totalCashInHand);
      setTotalSale(totalSale);
      setCashData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    addDateHandler();
    StockData();
  }, []);

  const data12 = [
    ["Task", "Hours per Day"],
    ["Profit", TotalProfit],
    ["Expense", Expense],
    ["Bottle", Bottle],
    ["Biscuit", Biscuit],
  ];

  const options12 = {
    title: "Profit and loss",
  };

  return (
    <div>
      {/* <form style={{ margin: "1rem" }}>
        <div className="mb-3">
          <label htmlFor="startDate" className="form-label">
            Start Date
          </label>
          <input
            type="date"
            className="form-control"
            id="startDate"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              setEndDate(e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="endDate" className="form-label">
            End Date
          </label>
          <input
            type="date"
            className="form-control"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        <button
          type="submit"
          className="btn-get-started-main"
          onClick={addDateHandler}
        >
          Search
        </button>
      </form> */}

      <h1
        style={{
          fontWeight: "700",
          margin: "1rem",
          color: "#400D4E",
          fontSize: "30px",
        }}
      >
        Dashboard Galla Parchi
      </h1>
      <div className="af_main">
        <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#94c875",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Sale</h4>
            <h1 className="card-title">{TotalSale}</h1>
          </div>
        </div>
        <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#dd3f5b",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Offline Expenses </h4>
            <h1 className="card-title">{Expense}</h1>
          </div>
        </div>
        <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#dc9245",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Online Expenses </h4>
            <h1 className="card-title">{onlineExpense}</h1>
          </div>
        </div>
        <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#7425A7",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Online</h4>
            <h1 className="card-title">{online}</h1>
          </div>
        </div>
        <div
          className="card"
          style={{
            width: "16rem",
            marginTop: "1.5rem",
            backgroundColor: "#55C0D6",
            color: "white",
          }}
        >
          <div className="card-body">
            <h4 className="card-text">Total Cash In Hand </h4>
            <h1 className="card-title">{cashInHand}</h1>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "95%",
          padding: ".5rem",
          margin: ".5rem",
          backgroundColor: "iceblue",
          border: "1px solid black",
          borderRadius: "10px",
        }}
      >
        <h2>Business Sale</h2>
        <div style={{ overflowX: "scroll" }}>
        <ResponsiveContainer  width="100%"
              height={300} >
          <ComposedChart data={CashData}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke="#f5f5f5" />
            <Area
              type="monotone"
              dataKey="expense"
              fill="#8884d8"
              stroke="#8884d8"
            />
            <Bar dataKey="totalSale" barSize={5} fill="#413ea0" />
            <Line type="monotone" dataKey="cashInHand" stroke="#44F367" />
            <Line type="monotone" dataKey="online" stroke="#ff7300" />
          </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    
        <div
            style={{
              width: "95%",
              padding: ".5rem",
              margin: ".5rem",
              backgroundColor: "iceblue",
              border: "1px solid black",
              borderRadius: "10px",
            }}
        >
          <h2>Average</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <AreaChart
              width={650}
              height={250}
              data={avdata}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[17, "dataMax"]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="Average"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorPv)"
              />
            </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
      style={{
        width: "95%",
        padding: ".5rem",
        margin: ".5rem",
        backgroundColor: "iceblue",
        border: "1px solid black",
        borderRadius: "10px",
      }}
        >
          <h2>Expense</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <AreaChart
  
              data={expenseData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="Expense"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorPv)"
              />
            </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div
           style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Total Sale and Expenses</h2>
          <div>
            <Chart
              chartType="PieChart"
              data={data12}
              options={options12}
              width={"100%"}
              height={"auto"}
            />
          </div>
        </div>
        <div
        style={{
          width: "95%",
          padding: ".5rem",
          margin: ".5rem",
          backgroundColor: "iceblue",
          border: "1px solid black",
          borderRadius: "10px",
        }}
        >
          <h2>Item Sale</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <LineChart
        
              data={Ttdata}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Tooltip />
              <Line type="monotone" dataKey="totalSale" stroke="#8884d8" />
              <Line type="monotone" dataKey="chaiTotal" stroke="green" />
              <Line type="monotone" dataKey="bottleTotal" stroke="blue" />
              <Line type="monotone" dataKey="biscuitTotal" stroke="red" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
            </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Tea Sale</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <LineChart
     
              data={teaData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[100, "dataMax"]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Tea" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Pouch Sale</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <LineChart
     
              data={pouchData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[100, "dataMax"]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Tea" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Cigerette Sale</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <LineChart
     
              data={cigeretteData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[100, "dataMax"]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Tea" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Biscuit Sale</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <LineChart
        
              data={Ttdata}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[100, "dataMax"]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="biscuitTotal" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Bottle Sale</h2>
          <div style={{ overflowX: "scroll" }}>
          <ResponsiveContainer  width="100%"
              height={300} >
            <LineChart
          
              data={Ttdata}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[100, "dataMax"]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="bottleTotal" stroke="#8884d8" />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
      <div>
  <h2>Current Stocks</h2>
  <ResponsiveContainer width="100%" height={400}>
    <LineChart
      data={processedStockData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="tea" stroke="#8884d8" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="milk" stroke="#82ca9d" />
    </LineChart>
  </ResponsiveContainer>
</div>

          </div>
    
    </div>
  );
};

export default DataForDateDash;


