import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  ComposedChart,
  PieChart,
  Pie,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import "./dashboard.css";
import { NavLink } from "react-router-dom";
import { Chart } from "react-google-charts";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [avdata, setAvData] = useState([]);
  const [Ttdata, setTtData] = useState([]);
  const [CashData, setCashData] = useState([]);
  const [teaData, setTeaData] = useState([]);
  const [cigeretteData,setCigeretteData] = useState([])
  const [pouchData ,setPouchData] = useState([]);
  const [numSale, setNumSale] = useState([]);
  const [piTotalProfit, setPiTotalProfit] = useState(0);
  const [piTotalSale, setPiTotalSale] = useState(0);
  const [piExpense, setPiExpense] = useState(0);
  const [piOnlineExpense, setPiOnlineExpense] = useState(0);
  const [piBottle, setPiBottel] = useState(0);
  const [piBiscuit, setPiBiscuit] = useState(0);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [loading, setLoading] = useState(false);
  const [Tonline, setTonline] = useState(0);
  const [Tcashinhand, setTcashinhand] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  //stocks updates
  const [stockdata, setStockData] = useState([]);
  const [processedStockData, setProcessedStockData] = useState([]);
 

  const navigate = useNavigate();

  const DateByData = () => {
    if (!startDate || !endDate) {
      alert("Please fill the date correctly");
      return;
    }
    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    navigate("/table-dash", { state: data });
  };

  //pie chart
  const data12 = [
    ["Task", "Hours per Day"],
    ["Profit", piTotalProfit],
    ["Expense", piExpense],
    ["Bottle", piBottle],
    ["Biscuit", piBiscuit],
  ];
  const options12 = {
    title: "Profit and loss",
  };
  //------------

  //----------------- Data By Range
  const handleDateByRange = () => {
    setShowModal(false);
    navigate("/table");
  };

  //stock data
  const StockData = async () => {
    const id = localStorage.getItem("key");
    try {
      setLoading(true);
      // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}item-stock/user/${id}`
      );
      setStockData(response.data);
      setLoading(false);
    } catch (err) {
      console.error("An error occurred while fetching data");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (stockdata.length > 0) {
      processData();
      // extractCurrentStocks();
    }
  }, [stockdata]);
  const processData = () => {
    const processed = stockdata.reduce((acc, item) => {
      item.stockDeductions.forEach((deduction) => {
        const date = moment(deduction.date).format("MM/DD");
        const existingEntry = acc.find((entry) => entry.date === date);
  
        if (existingEntry) {
          // Accumulate the current stock for the current item
          existingEntry[item.itemName] =
            (existingEntry[item.itemName] || 0) + deduction.currentStock;
        } else {
          // Create a new entry if not found for the date
          const newEntry = { date };
          newEntry[item.itemName] = deduction.currentStock;
          acc.push(newEntry);
        }
      });
      return acc;
    }, []);
  
    // Sorting based on date in MM/DD format
    processed.sort(
      (a, b) =>
        moment(a.date, "MM/DD").valueOf() - moment(b.date, "MM/DD").valueOf()
    );
  
    setProcessedStockData(processed); // Assuming this is a state setter function
  };
  

  // const extractCurrentStocks = () => {
  //   const stocks = stockdata.reduce((acc, item) => {
  //     acc[item.itemName] = item.currentStock;
  //     return acc;
  //   }, {});
  //   setCurrentStocks(stocks);
  // };
  //Average
  const callAverageMonthly = async (id) => {
    setLoading(true);
    const currentDate = new Date();

    // Get the current month (returns a number between 0 and 11)
    const currentMonth = currentDate.getMonth() + 1;

    // Get the current year
    const currentYear = currentDate.getFullYear();

    setMonth(currentMonth);
    setYear(currentYear);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}calculation/average/monthly`,
        {
          params: {
            year: currentYear,
            month: currentMonth,
            userId: id,
          },
        }
      );
      const responseData = response.data;
      console.log("area avData - ", responseData);

      // Map the keys and values to the desired format
      const formattedData = Object.entries(responseData).map(
        ([date, value]) => ({
          name: moment(date).format("DD/MM"),
          Average: value,
        })
      );

      // Set the formatted data
      setAvData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const callAverageWeekly = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}calculation/average/last7days`,
        {
          params: {
            userId: id,
          },
        }
      );
      const responseData = response.data;

      // Map the keys and values to the desired format
      const formattedData = Object.entries(responseData).map(
        ([date, value]) => ({ name: date, Average: value })
      );

      // Set the formatted data
      setAvData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const callAverageYearly = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}calculation/average/yearly`,
        {
          params: {
            year: year,
            userId: id,
          },
        }
      );
      const responseData = response.data;

      // Map the keys and values to the desired format
      const formattedData = Object.entries(responseData).map(
        ([date, value]) => ({ name: date, Average: value })
      );

      // Set the formatted data
      setAvData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //Total Sale Chai Biscuit Bottle
  const callTotalSaleMonthly = async (id) => {
    const currentDate = new Date();

    // Get the current month (returns a number between 0 and 11)
    const currentMonth = currentDate.getMonth() + 1;

    // Get the current year
    const currentYear = currentDate.getFullYear();

    setMonth(currentMonth);
    setYear(currentYear);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}data/monthly-data`,
        {
          params: {
            year: currentYear,
            month: currentMonth,
            userId: id,
          },
        }
      );
      const responseData = response.data;

      const totalSaleData = responseData.totalSale;
      const chaiTotalData = responseData.chaiTotal;
      const bottleAmountData = responseData.bottleAmount;
      const biscuitAmountData = responseData.biscuitAmount;
      const cigeretteSale = responseData.ciggaratteAmount;
      const pouchSale = responseData.pouchAmount;

      // Combine all data into a single array
      const formattedData = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          totalSale: totalSaleValue,
          chaiTotal: chaiTotalData[date],
          bottleTotal: bottleAmountData[date],
          biscuitTotal: biscuitAmountData[date],
          cigeretteTotal : cigeretteSale[date],
          pouchTotal : pouchSale[date]
        })
      );

      const TeaFormated = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: chaiTotalData[date] / 10,
        })
      );

      
      setTeaData(TeaFormated);

      const pouchFormatted =Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: pouchSale[date],
        })
      );
      setPouchData(pouchFormatted);



      const cigeretteFormatted =Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: cigeretteSale[date],
        })
      );
      setCigeretteData(cigeretteFormatted);
      // Calculate the sum of chai, bottle, and biscuit totals
      const totalChai = Object.values(chaiTotalData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalSale = Object.values(totalSaleData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalBottle = Object.values(bottleAmountData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalBiscuit = Object.values(biscuitAmountData).reduce(
        (acc, value) => acc + value,
        0
      );

      //setting the total sale
      setPiBottel(totalBottle);
      setPiBiscuit(totalBiscuit);
      setPiTotalSale(totalSale);

      // Set the formatted data
      setTtData(formattedData);

      // Now you can use totalChai, totalBottle, and totalBiscuit as needed
      // console.log(
      //   `Total Chai: ${totalChai}, Total Bottle: ${totalBottle}, Total Biscuit: ${totalBiscuit},Total Sale: ${totalSale}`
      // );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  const callTotalSaleWeekly = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}data/last7daysales`,
        {
          params: {
            userId: id,
          },
        }
      );
      const responseData = response.data;
      const totalSaleData = responseData.totalSale;
      const chaiTotalData = responseData.chaiTotal;
      const bottleAmountData = responseData.bottleAmount;
      const biscuitAmountData = responseData.biscuitAmount;
      const cigeretteSale = responseData.ciggaratteAmount;
      const pouchSale = responseData.pouchAmount;

      // Combine all data into a single array
      const formattedData = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          totalSale: totalSaleValue,
          chaiTotal: chaiTotalData[date],
          bottleTotal: bottleAmountData[date],
          biscuitTotal: biscuitAmountData[date],
          cigeretteTotal : cigeretteSale[date],
          pouchTotal : pouchSale[date]
        })
      );

      const TeaFormated = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: chaiTotalData[date] / 10,
        })
      );

      setTeaData(TeaFormated);

      const pouchFormatted =Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: pouchSale[date],
        })
      );
      setPouchData(pouchFormatted);



      const cigeretteFormatted =Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: cigeretteSale[date],
        })
      );
      setCigeretteData(cigeretteFormatted);

      const totalChai = Object.values(chaiTotalData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalSale = Object.values(totalSaleData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalBottle = Object.values(bottleAmountData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalBiscuit = Object.values(biscuitAmountData).reduce(
        (acc, value) => acc + value,
        0
      );

      //setting the total sale
      setPiBottel(totalBottle);
      setPiBiscuit(totalBiscuit);
      setPiTotalSale(totalSale);

      // Set the formatted data
      setTtData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  const callTotalSaleYearly = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}data/yearly`,
        {
          params: {
            year: year,
            userId: id,
          },
        }
      );
      const responseData = response.data;

      const totalSaleData = responseData.totalSale;
      const chaiTotalData = responseData.chaiTotal;
      const bottleAmountData = responseData.bottleAmount;
      const biscuitAmountData = responseData.biscuitAmount;
      const cigeretteSale = responseData.ciggaratteAmount;
      const pouchSale = responseData.pouchAmount;

      // Combine all data into a single array
      const formattedData = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          totalSale: totalSaleValue,
          chaiTotal: chaiTotalData[date],
          bottleTotal: bottleAmountData[date],
          biscuitTotal: biscuitAmountData[date],
          cigeretteTotal : cigeretteSale[date],
          pouchTotal : pouchSale[date]
        })
      );

      const TeaFormated = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: chaiTotalData[date] / 10,
        })
      );

      setTeaData(TeaFormated);

      const pouchFormatted =Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: pouchSale[date],
        })
      );
      setPouchData(pouchFormatted);



      const cigeretteFormatted =Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          Tea: cigeretteSale[date],
        })
      );
      setCigeretteData(cigeretteFormatted);

      const totalChai = Object.values(chaiTotalData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalSale = Object.values(totalSaleData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalBottle = Object.values(bottleAmountData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalBiscuit = Object.values(biscuitAmountData).reduce(
        (acc, value) => acc + value,
        0
      );

      //setting the total sale
      setPiBottel(totalBottle);
      setPiBiscuit(totalBiscuit);
      setPiTotalSale(totalSale);

      // Set the formatted data
      setTtData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  //Cash Expense online cash in hand
  const callCashMonthly = async (id) => {
    const currentDate = new Date();

    // Get the current month (returns a number between 0 and 11)
    const currentMonth = currentDate.getMonth() + 1;

    // Get the current year
    const currentYear = currentDate.getFullYear();

    setMonth(currentMonth);
    setYear(currentYear);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}cash/monthly`,
        {
          params: {
            year: currentYear,
            month: currentMonth,
            userId: id,
          },
        }
      );
      const responseData = response.data;
      console.log("call cash monthly @@ -" + " " + responseData);
      const totalSaleData = responseData.totalSale;
      const chaiTotalData = responseData.expense;
      const bottleAmountData = responseData.online;
      const biscuitAmountData = responseData.cashInHand;
      const onlineExpense = responseData.onlineExpense;

      // Combine all data into a single array
      const formattedData = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          totalSale: totalSaleValue,
          Expense: chaiTotalData[date],
          online: bottleAmountData[date],
          CashInHand: biscuitAmountData[date],
        })
      );

      //  __-----------------__________-------------

      const totalExpense = Object.values(chaiTotalData).reduce(
        (acc, value) => acc + value,
        0
      );

      const totalSalePrice = Object.values(totalSaleData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalOnline = Object.values(bottleAmountData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalCashInHand = Object.values(biscuitAmountData).reduce(
        (acc, value) => acc + value,
        0
      );

      const totalOnlineExpense = Object.values(onlineExpense).reduce(
        (acc, value) => acc + value,
        0
      );
      //setting online expense
      setPiOnlineExpense(totalOnlineExpense);

      setTonline(totalOnline);
      setTcashinhand(totalCashInHand);
      //setting the total Expense
      setPiExpense(totalExpense - totalOnlineExpense);

      const profit = totalSalePrice - totalExpense;
      setPiTotalProfit(profit);

      // Set the formatted data
      setCashData(formattedData);

      // Now you can use totalExpense as needed
      console.log(`Total Expense: ${totalExpense}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  const callCashWeekly = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}cash/weekly`,
        {
          params: {
            userId: id,
          },
        }
      );
      const responseData = response.data;
      console.log("call cash weekly @@" + " " + responseData);
      const totalSaleData = responseData.totalSale;
      const chaiTotalData = responseData.expense;
      const bottleAmountData = responseData.online;
      const biscuitAmountData = responseData.cashInHand;
      const onlineExpense = responseData.onlineExpense;

      // Combine all data into a single array
      const formattedData = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date.split("-")[2],
          totalSale: totalSaleValue,
          Expense: chaiTotalData[date],
          online: bottleAmountData[date],
          CashInHand: biscuitAmountData[date],
        })
      );

      const totalExpense = Object.values(chaiTotalData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalSalePrice = Object.values(totalSaleData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalOnline = Object.values(bottleAmountData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalCashInHand = Object.values(biscuitAmountData).reduce(
        (acc, value) => acc + value,
        0
      );

      const totalOnlineExpense = Object.values(onlineExpense).reduce(
        (acc, value) => acc + value,
        0
      );
      //setting online expense
      setPiOnlineExpense(totalOnlineExpense);

      setTonline(totalOnline);
      setTcashinhand(totalCashInHand);
      //setting the total Expense
      setPiExpense(totalExpense - totalOnlineExpense);

      const profit = totalSalePrice - totalExpense;
      setPiTotalProfit(profit);
      //setting the total Expense
      setPiExpense(totalExpense - totalOnlineExpense);

      // Set the formatted data
      setCashData(formattedData);

      // Now you can use totalExpense as needed
      console.log(`Total Expense: ${totalExpense}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  const callCashYearly = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}cash/yearly`,
        {
          params: {
            year: year,
            userId: id,
          },
        }
      );
      const responseData = response.data;
      console.log("call cash yearly @@ -", responseData);
      const totalSaleData = responseData.totalSale;
      const chaiTotalData = responseData.expense;
      const bottleAmountData = responseData.online;
      const biscuitAmountData = responseData.cashInHand;
      const onlineExpense = responseData.onlineExpense;

      // Combine all data into a single array
      const formattedData = Object.entries(totalSaleData).map(
        ([date, totalSaleValue]) => ({
          name: date,
          totalSale: totalSaleValue,
          Expense: chaiTotalData[date],
          online: bottleAmountData[date],
          CashInHand: biscuitAmountData[date],
        })
      );
      const totalExpense = Object.values(chaiTotalData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalSalePrice = Object.values(totalSaleData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalOnline = Object.values(bottleAmountData).reduce(
        (acc, value) => acc + value,
        0
      );
      const totalCashInHand = Object.values(biscuitAmountData).reduce(
        (acc, value) => acc + value,
        0
      );

      const totalOnlineExpense = Object.values(onlineExpense).reduce(
        (acc, value) => acc + value,
        0
      );
      //setting online expense
      setPiOnlineExpense(totalOnlineExpense);
      setTonline(totalOnline);
      setTcashinhand(totalCashInHand);
      //setting the total Expense

      const profit = totalSalePrice - totalExpense;
      setPiTotalProfit(profit);
      //setting the total Expense
      setPiExpense(totalExpense - totalOnlineExpense);

      // Set the formatted data
      setCashData(formattedData);

      // Now you can use totalExpense as needed
      console.log(`Total Expense: ${totalExpense}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    const id = localStorage.getItem("key");

    callAverageMonthly(id);
    callTotalSaleMonthly(id);

    callCashMonthly(id);
    StockData();
  }, []);

  const weeklyData = () => {
    const id = localStorage.getItem("key");
    callAverageWeekly(id);
    callTotalSaleWeekly(id);
    callCashWeekly(id);
  };
  const monthlyData = () => {
    const id = localStorage.getItem("key");
    callAverageMonthly(id);
    callTotalSaleMonthly(id);

    callCashMonthly(id);
  };
  const YearlyData = () => {
    const id = localStorage.getItem("key");
    callAverageYearly(id);
    callTotalSaleYearly(id);
    callCashYearly(id);
  };
  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <GridLoader size={50} color="black" />
        </div>
      )}
      <div className={`${loading ? "blur" : ""}`}>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            style={{ width: "100vw", backgroundColor: "#240a2b" }}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Select Timeframe
          </button>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item" onClick={weeklyData}>
                This Weekly Data
              </a>
            </li>
            <li>
              <a className="dropdown-item" onClick={YearlyData}>
                This Yearly Data
              </a>
            </li>
            <li>
              <a className="dropdown-item" onClick={monthlyData} href="#">
                This Monthly Data
              </a>
            </li>
          </ul>
        </div>

        <h1
          style={{
            fontWeight: "700",
            margin: "1rem",
            color: "#400D4E",
            fontSize: "30px",
          }}
        >
          Dashboard Galla Parchi
        </h1>
        <form style={{ margin: "1rem" }}>
          <div className="mb-3">
            <label htmlFor="startDate" className="form-label">
              Start Date
            </label>
            <input
              type="date"
              className="form-control"
              id="startDate"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="endDate" className="form-label">
              End Date
            </label>
            <input
              type="date"
              className="form-control"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className="btn-get-started-main"
            onClick={DateByData}
          >
            Search
          </button>
        </form>
        <div className="af_main">
          <div
            className="card"
            style={{
              width: "16rem",
              marginTop: "1.5rem",
              backgroundColor: "#94c875",
              color: "white",
            }}
          >
            <div className="card-body">
              <h4 className="caard-text">Total Sale</h4>
              <h1 className="card-title">{piTotalSale}</h1>
            </div>
          </div>

          <div
            className="card"
            style={{
              width: "16rem",
              marginTop: "1.5rem",
              backgroundColor: "#dd3f5b",
              color: "white",
            }}
          >
            <div className="card-body">
              <h4 className="card-text">Total Offline Expenses </h4>
              <h1 className="card-title">{piExpense}</h1>
            </div>
          </div>
          <div
            className="card"
            style={{
              width: "16rem",
              marginTop: "1.5rem",
              backgroundColor: "#dc9245",
              color: "white",
            }}
          >
            <div className="card-body">
              <h4 className="card-text">Total Online Expenses </h4>
              <h1 className="card-title">{piOnlineExpense}</h1>
            </div>
          </div>
          <div
            className="card"
            style={{
              width: "16rem",
              marginTop: "1.5rem",
              backgroundColor: "#7425A7",
              color: "white",
            }}
          >
            <div className="card-body">
              <h4 className="caard-text">Total Online</h4>
              <h1 className="card-title">{Tonline}</h1>
            </div>
          </div>

          <div
            className="card"
            style={{
              width: "16rem",
              marginTop: "1.5rem",
              backgroundColor: "#55C0D6",
              color: "white",
            }}
          >
            <div className="card-body">
              <h4 className="card-text">Total Cash In Hand </h4>
              <h1 className="card-title">{Tcashinhand}</h1>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Business Sale</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={CashData}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid stroke="#f5f5f5" />
                <Area
                  type="monotone"
                  dataKey="Expense"
                  fill="#8884d8"
                  stroke="#8884d8"
                />
                <Bar dataKey="totalSale" barSize={5} fill="#413ea0" />
                <Line type="monotone" dataKey="CashInHand" stroke="#44F367" />
                <Line type="monotone" dataKey="online" stroke="#ff7300" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Average</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                data={avdata}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[17, "dataMax"]} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="Average"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Expense</h2>
          <div>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                data={CashData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[500, "dataMax"]} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="Expense"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: "1rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Total Sale and Expenses</h2>
          <div>
            <Chart
              chartType="PieChart"
              data={data12}
              options={options12}
              width={"100%"} // Set width to 100% of the parent container
              height={"auto"} // Set a fixed height or use percentages
            />
          </div>
        </div>
        {/* <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Item Sale</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer  width="100%"
              height={300} >
            <LineChart
             
              data={Ttdata}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Tooltip />
              <Line type="monotone" dataKey="totalSale" stroke="#8884d8" />
              <Line type="monotone" dataKey="chaiTotal" stroke="green" />
              <Line type="monotone" dataKey="bottleTotal" stroke="blue" />
              <Line type="monotone" dataKey="biscuitTotal" stroke="red" />
        
              <XAxis dataKey="name" />
              <YAxis />
            </LineChart>
            </ResponsiveContainer>
          </div>
        </div> */}
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Biscuit Sale</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={Ttdata}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[100, "dataMax"]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="biscuitTotal" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Tea Sale</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={teaData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[100, "dataMax"]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Tea" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Cigerette Sale</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={cigeretteData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[100, "dataMax"]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Tea" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Pouch Sale</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={pouchData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[100, "dataMax"]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Tea" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>Bottle Sale</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={Ttdata}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[100, "dataMax"]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="bottleTotal" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
         <div>
  <h2>Current Stocks</h2>
  <ResponsiveContainer width="100%" height={400}>
    <LineChart
      data={processedStockData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="tea" stroke="#8884d8" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="milk" stroke="#82ca9d" />
    </LineChart>
  </ResponsiveContainer>
</div>

        </div>
      </div>
    </>
  );
};

export default Dashboard;
